import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { usePrivacyConsent } from '../../../hooks/usePrivacy';
import { useModal } from '../../../hooks/useModal';
import { Button } from '../../common/button';
import { Content } from '../../common/content';
import { SignUpForm } from '../../common/newsletter';
import './newsletter.scss';

const bem = new BEMHelper({
  name: 'content-newsletter',
});

export const Newsletter = ({
  id,
  headline,
  description,
  mailinglist: [mailinglist],
}) => {
  const { t } = useTranslation();
  const canUseContents = usePrivacyConsent('contents');

  const { showModal, hideModal } = useModal(SignUpForm, {
    title: t('Subscribe our newsletter'),
    componentProps: {
      submit: {
        label: t('Subscribe'),
        title: t('Click here to subscribe'),
      },
      mailinglist,
      onSuccess: () => hideModal(),
    },
  });

  return (
    <Content id={id} {...bem()} useOwnSpacing>
      <h2 {...bem('headline')}>{headline}</h2>
      <div {...bem('details')}>
        <p {...bem('description')}>{description}</p>
        {canUseContents
          ? (
            <Button
              {...bem('button')}
              title={t('Click here to subscribe')}
              onClick={showModal}
            >
              {t('Subscribe now')}
            </Button>
          )
          : (
            <Button
              {...bem('button')}
              title={t('Click here to subscribe')}
              href={mailinglist.endpoint}
              rel="noopener"
              target="_blank"
            >
              {t('Subscribe now')}
            </Button>
          )}
      </div>
    </Content>
  );
};

Newsletter.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  description: string.isRequired,
  mailinglist: arrayOf(shape({
    headline: string.isRequired,
    description: string.isRequired,
    endpoint: string.isRequired,
  })).isRequired,
};

export const fragment = graphql`
  fragment NewsletterContentFragment on CraftGQL_pageContents_newsletter_BlockType {
    id
    headline
    description
    mailinglist {
      ...on CraftGQL_mailinglists_mailinglists_Entry {
        headline: mailinglistHeadline
        description: mailinglistDescription
        endpoint: mailinglistEndpoint
      }
    }
  }
`;
